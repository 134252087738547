import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import Box from '../../reusecore/src/elements/Box';
import Text from '../../reusecore/src/elements/Text';
import Heading from '../../reusecore/src/elements/Heading';
import Button from '../../reusecore/src/elements/Button';
import FeatureBlock from '../common/src/components/FeatureBlock';
import Input from '../../reusecore/src/elements/Input';
import Container from '../common/src/components/UI/Container';

// import ContactFromWrapper, {  FormWrapper } from './contact.style';

const ContactSection = ({
  sectionWrapper,
  row,
  contactForm,
  secTitleWrapper,
  secHeading,
  secText,
  button,
  note,
  title,
  description,
  colornote,
}) => {
  const contactRef = useRef("contact")
  return (
    <div className="SectionMainWrapper" ref={contactRef}>
      <Box {...sectionWrapper}>
        <Container className="containerClass">
          <Box {...secTitleWrapper}>
            <FeatureBlock
              title={<Heading {...title} />}
              description={<Text {...description} />}
            />
          </Box>
          
          <Box {...row}>

            <Box {...contactForm}>

              <form  action="https://finshots.us18.list-manage.com/subscribe/post?u=4586001b135729fc977641e60&amp;id=5356ccb39e" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate ContactFromWrapper" target="_blank" noValidate="">

                 <Input
                  inputType="email"
                  placeholder="Enter Your Email address"
                  iconPosition="right"
                  isMaterial={false}
                  className="email_input"
                  aria-label="email"
                  nameValue="EMAIL"
                  id="mce-EMAIL"
                />
                <div className="InvisibleDiv" aria-hidden="true">
                <input type="text" name="b_4586001b135729fc977641e60_5356ccb39e" tabIndex="-1" value="" readOnly/>
                </div>
                <Button {...button} type="submit" id="mc-embedded-subscribe" name="subscribe" title="SUBSCRIBE" />
              </form>

              <Box className="contactdes">
                <Text
                  as="span"
                  {...note}
                  content="Be a part of our ever growing community. "
                />
                <a href="https://twitter.com/finshots" target="_blank" className="">
                  Join us on Twitter.{' '}
                </a>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </div>
  );
};

ContactSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  contactForm: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  button: PropTypes.object,
  note: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  colornote: PropTypes.object,
};

ContactSection.defaultProps = {
  sectionWrapper: {
    id: 'contact_section',
    as: 'section',
    pt: ['8px', '80px', '80px', '80px'],
    pb: ['0', '80px', '80px', '80px', '80px'],
  },
  secTitleWrapper: {
    mb: ['0px', '0px', '0px'],
    p: ['0 15px', 0, 0, 0, 0],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: `${2}`,
    letterSpacing: '0.15em',
    fontWeight: `${6}`,
    color: 'primary',
    mb: `${3}`,
  },
  secHeading: {
    textAlign: 'center',
    fontSize: [`${6}`, `${8}`],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: `${0}`,
  },
  row: {
    flexBox: true,
    justifyContent: 'center',
  },
  contactForm: {
    width: [1, 1, 1, 1 / 2],
  },
  button: {
    type: 'button',
    fontSize: `${2}`,
    fontWeight: '600',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: `${4}`,
  },
  note: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  colornote: {
    fontSize: '16px',
    fontWeight: '500',
    color: 'rgb(106, 82, 253)',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  title: {
    content: 'Join 1,00,000+ subscribers',
    fontSize: ['20px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '20px',
    textAlign: ['center', 'center'],
  },

  description: {
    content: 'Just one article every day. Pinky promise 🙌',
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
};

export default ContactSection;
