import React, { useEffect } from "react";
import ReactModal from "react-modal";
import noScroll from "no-scroll";

import Loader from "../../reusecore/src/elements/Loader";
import Button from "../../reusecore/src/elements/Button";

var customStyles = {
    content: {
        top: `50%`,
        left: `50%`,
        right: `auto`,
        bottom: `auto`,
        marginRight: `-50%`,
        transform: `translate(-50%, -50%)`,
        height: `auto`, // <-- This sets the height
        overlfow: `scroll`, // <-- This tells the modal to scrol
    },
};

const Loading = ({ open, text, message, showMessage, onAccept, onClose }) => {
    ReactModal.setAppElement(`#___gatsby`);

    useEffect(() => {
        if (open) {
            noScroll.on();
        }
        return () => noScroll.off();
    });

    return (
        <ReactModal
            isOpen={open}
            
            contentLabel="Loading"
            style={customStyles}
            shouldCloseOnOverlayClick={false}
        >
            {!showMessage ? (<div style={{ width: `100%`, textAlign: `center` }}>
                <Loader loaderColor="#3eb0ef" />
                {text && <div>{text}</div>}
            </div>) : (<div style={{ width: `100%`, textAlign: `center` }}>
                <h3 style={{ marginBottom: 20 }}>{message}</h3>
               
                <Button
                    id="mc-embedded-subscribe"
                    type="button"
                    title="Close"
                    onClick={onClose}
                    style={{ backgroundColor: `white`, marginRight: `10px`, color: `rgb(106, 82, 253)` }}
                />
                <Button
                    id="mc-embedded-subscribe"
                    type="button"
                    title="Login"
                    onClick={onAccept}
                />
            </div>)}
            
        </ReactModal>
    );
};

export default Loading;
