import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import ReactModal from "react-modal";
import noScroll from "no-scroll";

import Input from "../../reusecore/src/elements/Input";
import Button from "../../reusecore/src/elements/Button";
import Loader from "../../reusecore/src/elements/Loader";

var customStyles = {
    content: {
        top: `50%`,
        left: `50%`,
        right: `auto`,
        bottom: `auto`,
        marginRight: `-50%`,
        transform: `translate(-50%, -50%)`,
        height: `auto`, // <-- This sets the height
        overlfow: `scroll`, // <-- This tells the modal to scrol
    },
};

const validateEmail = (inputText) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (inputText.match(mailformat)) {
        return true;
    }
    return false;
};

const ProfileForm = ({ open, openId, toggleModal, needEmail, needName }) => {
    ReactModal.setAppElement(`#___gatsby`);

    const [cookies, setCookie] = useCookies([`loggedIn`, `init`]);
    const [email, setEmail] = useState(``);
    const [name, setName] = useState(``);
    const [error, setError] = useState(``);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (open) {
            noScroll.on();
            document.body.style.overflow = `hidden`;
        }
        return () => {
            noScroll.off();
            document.body.style.overflow = `unset`;
        };
    });

    const storeDetails = (event) => {
        event.preventDefault();
        let body = { openId };
        if (needName && name === ``) {
            setError(`Please enter your name.`);
            return;
        } else if (name !== ``) {
            body.name = name;
        }

        if (needEmail && email === ``) {
            setError(`Please enter your email`);
            return;
        } else if (!validateEmail(email)){
            setError(`Invalid email`);
            return;
        } else if (email !== ``) {
            body.email = email;
        }

        setLoading(true);

        const requestOptions = {
            method: `POST`,
            headers: { "Content-Type": `application/json` },
            body: JSON.stringify(body),
        };
        fetch(
            `https://backend.finshots.in/backend/paytm/userdetails/`,
            requestOptions
        )
            .then((res) => {
                setLoading(false);
                if (res.status === 200) {
                    setCookie(`loggedIn`, true, {
                        path: `/`,
                    });
                    setSuccess(true);
                } else {
                    setError(
                        `An error occured while storing your details. Please try again!`
                    );
                }
            })
            .catch((error) => {
                setLoading(false);
                setError(
                    `An error occured while storing your details. Please try again!`
                );
            });
    };

    return (
        <ReactModal
            isOpen={open}
            onRequestClose={() => {
                toggleModal(false);
            }}
            contentLabel="Details incomplete"
            style={customStyles}
            shouldCloseOnOverlayClick={false}
        >
            <div className={`validate ${!loading ? `ContactFromWrapper` : ``}`}>
                {!loading ? (
                    !success ? (
                        <>
                            <h1 className="content-title" align="center">
                                Paytm Profile Incomplete
                            </h1>
                            <p>
                                Please provide following details to continue
                                using Finshots
                            </p>
                            {error && error !== `` && (
                                <div
                                    style={{
                                        padding: `3px`,
                                        color: `red`,
                                        marginBottom: `10px`,
                                    }}
                                >
                                    {error}
                                </div>
                            )}
                            <form
                                onSubmit={storeDetails}
                                style={{ width: `100%` }}
                            >
                                {needName ? (
                                    <Input
                                        inputType="text"
                                        placeholder="Enter Your Name"
                                        iconPosition="right"
                                        isMaterial={false}
                                        className="email_input"
                                        aria-label="Name"
                                        onChange={(val) => {
                                            setError(``);
                                            setName(val);
                                        }}
                                        value={name}
                                    />
                                ) : null}
                                {needEmail ? (
                                    <Input
                                        inputType="email"
                                        placeholder="Enter Your Email address"
                                        iconPosition="right"
                                        isMaterial={false}
                                        className="email_input"
                                        aria-label="Email"
                                        value={email}
                                        onChange={(val) => {
                                            setError(``);
                                            setEmail(val);
                                        }}
                                    />
                                ) : null}
                                <Button
                                    id="mc-embedded-subscribe"
                                    type="submit"
                                    title="Update Profile"
                                />
                            </form>
                        </>
                    ) : (
                        <div>
                            <h3>Profile updated successfully.</h3>
                            <Button
                                id="mc-embedded-subscribe"
                                type="button"
                                title="Ok"
                                onClick={() => {
                                    toggleModal(false);
                                }}
                            />
                        </div>
                    )
                ) : (
                    <div style={{ width: `100%`, textAlign: `center` }}>
                        <Loader loaderColor="#3eb0ef" />
                        <div>Updating your profile...</div>
                    </div>
                )}
            </div>
        </ReactModal>
    );
};

export default ProfileForm;
