import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { useCookies } from "react-cookie";

import { Layout, PostCard, Pagination } from "../components/common";
import { MetaData } from "../components/common/meta";
import Contact from "../components/Contact";
import { isInPaytm } from "../utils/paytm";
import ProfileForm from "../components/common/ProfileForm";
import Loading from "../components/common/Loading";
/**
 * Main index page (home page)
 *
 * Loads all posts from Ghost and uses pagination to navigate through them.
 * The number of posts that should appear per page can be setup
 * in /utils/siteConfig.js under `postsPerPage`.
 *
 */

const Index = ({ data, location, pageContext }) => {
    const posts = data.allGhostPost.edges;
    const [cookies, setCookie] = useCookies([`loggedIn`]);
    const [modal, setModal] = useState(false);
    const [needName, setNeedName] = useState(false);
    const [needEmail, setNeedEmail] = useState(false);
    const [openId, setOpenId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(``);
    const [showMessage, setShowMessage] = useState(false);

    const ready = (callback) => {
        // call if jsbridge is injected
        if (window.JSBridge) {
            callback && callback();
        } else {
            // listen to jsbridge ready event
            document.addEventListener(`JSBridgeReady`, callback, false);
        }
    };

    const login = () => {
        window.JSBridge.call(
            `paytmFetchAuthCode`,
            {
                clientId: `merchant-finshots-prod`,
                //`merchant-finshots-uat`,
            },
            function (result) {
                setLoading(true);
                if (!result.error) {
                    const requestOptions = {
                        method: `POST`,
                        headers: { "Content-Type": `application/json` },
                        body: JSON.stringify({
                            authId: result.data.authId,
                            openId: result.data.openId,
                        }),
                    };
                    fetch(
                        `https://backend.finshots.in/backend/paytm/register/`,
                        requestOptions
                    )
                        .then(response => response.json())
                        .then((data) => {
                            if (data.openId) {
                                if (!data.needName && !data.needEmail) {
                                    setCookie(`loggedIn`, true, {
                                        path: `/`,
                                    });
                                    setLoading(false);
                                    setShowMessage(false);
                                    setMessage(``);
                                } else {
                                    // show popup
                                    setNeedName(data.needName);
                                    setNeedEmail(data.needEmail);
                                    setOpenId(data.openId);
                                    setLoading(false);
                                    setShowMessage(false);
                                    setMessage(``);
                                    setModal(true);
                                }
                            } else {
                                // show alert messge if needed
                                setShowMessage(true);
                                setMessage(`Login is mandatory to proceed`);
                            }
                        })
                        .catch((err) => {
                            // console.log(err);
                            setShowMessage(true);
                            setMessage(`An error occured. Please try again.`);
                        });
                } else {
                    // show alert messge if needed
                    setShowMessage(true);
                    let errMessage = `Login is mandatory to proceed`;
                    if (result.error === -1){
                        errMessage = `Consent is mandatory to proceed`;
                    }
                    setMessage(errMessage);
                }
            }
        );
    };

    useEffect(() => {
        if (isInPaytm() && !cookies.loggedIn && cookies.loggedIn !== `false`) {
            ready(login);
        }
    }, []);

    return (
        <>
            <MetaData location={location} />
            <Layout isHome={true}>
                <div className="container">
                    <section className="post-feed">
                        {posts.map(({ node }) => {
                            // The tag below includes the markup for each post - components/common/PostCard.js
                            let noPaytm = false;
                            node.tags.map((tag) => {
                                if (tag.slug === `hash-no-paytm` && !noPaytm){
                                    noPaytm = true;
                                }
                            });
                            return !noPaytm ? <PostCard key={node.id} post={node} /> : null;
                        })}
                    </section>
                    <Pagination pageContext={pageContext} />
                </div>
                {/* <Contact /> */}
                <ProfileForm
                    open={modal}
                    openId={openId}
                    toggleModal={val => setModal(val)}
                    needName={needName}
                    needEmail={needEmail}
                />
                <Loading
                    open={loading}
                    toggleModal={() => {}}
                    text="Logging in..."
                    message={message}
                    showMessage={showMessage}
                    onAccept={() => {
                        ready(login);
                        setShowMessage(false);
                        setMessage(``);
                    }}
                    onClose={() => {
                        window.JSBridge.call(`popWindow`);
                        setShowMessage(false);
                        setMessage(``);
                        setLoading(true);
                    }}
                />
            </Layout>
        </>
    );
};

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
};

export default Index;

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
    query GhostPostQuery($limit: Int!, $skip: Int!) {
        allGhostPost(
            filter: {
                primary_tag: { name: { nin: ["infographic", "markets", "Weekly"] } },
                tags: {elemMatch: {slug: {nin: ["hash-no-paytm"]}}}
            }
            sort: { order: DESC, fields: [published_at] }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    ...GhostPostFields
                }
            }
        }
    }
`;
